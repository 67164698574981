import moment from "moment";

export const customersTableConfig = {
    fields: [
        {
            key: "name",
            label: "Nome",
            sortDirection: "asc",
            class: "text-left",
        },
        {
            key: "received_count",
            label: "Disparados",
        },
        {
            key: "open_count",
            label: "Abertos",
        },
        {
            key: "answered_count",
            label: "Respondidos",
        },
        {
            key: "score",
            label: "Score",
        },
        {
            key: "actions",
            label: ""
        },
    ]
}

export const customersFilters = {
    search: null,
    form_id: null,
    phone_id: null,
    campaign_id: null,
    order: null,
    order_column: null,
    sortPagination: true,
    score: null,
    networks: null,
    whatsapp: null,
    initial_date: null,
    final_date: null
};

export const customersAnswersTableConfig = {
    fields: [
        {
            key: "answer",
            label: "Resposta",
            sortable: false,
            class: "text-left",
        },
        {
            key: "campaign_name",
            label: "Campanha",
            sortable: false,
        },
        {
            key: "customer.name",
            label: "Cliente",
            sortable: false,
        },
        {
            key: "created_at",
            formatter: value => {
                return moment(value).format('DD/MM/YYYY')
            },
            label: "Recebido em",
            sortable: false,
        },
        {
            key: "actions",
            label: "",
            class: "text-right d-flex justify-content-around",
            sortable: false,
        },
    ],
    items: [],
    filters: {
        order_column: null,
        order: null,
        search: null
    },
    timeout: null
};

export const formsTableConfig = {
    fields: [
        {
            key: "name",
            label: "Nome",
            sortable: false,
            class: "text-left",
        },
        {
            key: "customers_count",
            label: "Coletados",
            sortable: false,
        },
        {
            key: "created_at",
            formatter: value => {
                return moment(value).format('DD/MM/YYYY');
            },
            label: "Data de criação",
            sortable: false,
        },
        {
            key: "actions",
            label: "",
            class: "text-right d-flex justify-content-around",
            sortable: false,
        },
    ],
    filters: {
        order_column: null,
        order: null,
        search: null
    },
    items: [],
    timeout: null,
};
