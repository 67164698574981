<template>
    <section class="position-relative">
        <loading v-if="loading"/>

        <common-table-custom
            title="Clientes Cadastrados"
            v-bind="customersTable"
        >
            <template v-slot:filters>
                <b-input-group
                    class="input-group-merge search flex-grow-1 mx-2 mt-2"
                >
                    <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" size="25"/>
                    </b-input-group-prepend>
                    <b-form-input
                        v-model="customersFilters.search"
                        placeholder="Buscar Cliente"
                    />

                    <select v-model="customersFilters.order_column" class="form-control like-dropdown" style="min-width: 120px;">
                        <option :value="null" selected>Filtrar por</option>
                        <!-- <option value="name">Nome</option> -->
                        <option value="created_at">Data de Cadastro</option>
                        <option value="score">Score</option>
                    </select>

                    <select v-model="customersFilters.order" class="form-control like-dropdown" style="min-width: 120px;">
                        <option :value="null" selected>Classificar por</option>
                        <option value="asc">Ascendente</option>
                        <option value="desc">Decrescente</option>
                    </select>


                    <select v-if="user.can_shot" v-model="customersFilters.campaign_id" class="form-control like-dropdown"
                            style="min-width: 120px;">
                        <option :value="null" selected>Campanha</option>
                        <option v-for="(campaign, i) in customersFiltersOptions.campaigns" :key="`campaign-option-${i}`"
                                :value="campaign._id">
                            {{ campaign.name }}
                        </option>
                    </select>
                </b-input-group>

                <b-input-group
                    class="d-flex align-items-end justify-content-center"
                    size="lg"
                >
                    <v-select v-model="customersFilters.form_id"
                              style="min-width: 180px;"
                              class="my-1 w-full custom"
                              :options="customersFiltersOptions.forms"
                              :get-option-label="option => option.name"
                              :reduce="option => option._id"
                              :filterable="false"
                              @search="onSearch"
                              append-icon="mdi-chevron-down"
                              placeholder="Formulário">
                        <span slot="no-options">Desculpe, não há opções correspondentes.</span>
                    </v-select>

                    <div class="d-flex flex-column text-left flex-grow-1 mx-2 mt-2">
                        <label class="m-0" for="filter-score">Score</label>
                        <b-form-rating
                            id="filter-score"
                            v-model="customersFilters.score"
                            class="rating-input"
                            variant="primary"
                        ></b-form-rating>
                    </div>

                    <div class="d-flex flex-column text-left flex-grow-1 mx-2 mt-2">
                        <label class="m-0" for="filter-start">Início</label>
                        <b-form-datepicker
                            id="filter-start"
                            v-model="customersFilters.initial_date"
                            :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                    }"
                            :max="new Date()"
                            locale="pt-BR"
                            placeholder="Data inicial"
                        ></b-form-datepicker>
                    </div>

                    <div class="d-flex flex-column text-left flex-grow-1 mx-2 mt-2">
                        <label class="m-0" for="filter-start">Final</label>
                        <b-form-datepicker
                            id="filter-final"
                            v-model="customersFilters.final_date"
                            :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                    }"
                            :max="new Date()"
                            locale="pt-BR"
                            placeholder="Data final"
                        ></b-form-datepicker>
                    </div>

                    <b-button
                        class="font-weight-bold flex-grow-1 mx-2 mt-2"
                        style="height: 35px;"
                        variant="secondary"
                        @click="clearFilters()"
                    >
                        Limpar
                    </b-button>

                    <b-button
                        class="font-weight-bold px-3 flex-grow-1 mx-2 mt-2"
                        style="height: 35px;"
                        variant="primary"
                        @click.prevent="getCustomers"
                    >
                        Filtrar
                    </b-button>
                </b-input-group>
            </template>
            <template v-slot:footer>
                <td class="text-left">Total</td>
                <td>{{ customersTable.misc ? customersTable.misc.total : 0 }}</td>
                <td
                    :colspan="5"
                    class="text-right"
                >
                    <b-link
                        v-if="customersTable.misc && customersTable.misc.current_page != customersTable.misc.last_page"
                        @click="getCustomers(customersTable.misc.current_page + 1)"
                    >
                        + Mostrar mais
                    </b-link>
                </td>
            </template>
            <template v-slot:details="item">
                <section class="flex-grow-1 d-flex flex-column form-container">
                    <RenderCustomerFields
                        :ref="`customerFieldsForm-${item.data._id}`"
                        :fields="parseFormValues(item.data.fields, item.data.data)"
                        class="d-flex flex-column h-100 flex-grow-1"
                        form-class="flex-grow-1"
                        tag="form"
                    >
                        <template v-slot:label>Mostra label</template>
                        <template v-slot:phone>
                            <label class="form-label">
                                Número de disparo
                            </label>
                            <select
                                v-model="item.data.phone_id"
                                class="flex-grow-1 form-control">
                                <option :value="null" selected>
                                    {{ customersFiltersOptions.phones.length === 0 ? 'Nenhuma opção encontrada' : 'Número de disparo' }}
                                </option>
                                <option v-for="phone in customersFiltersOptions.phones" :key="`mark-options-${phone._id}`"
                                        :value="phone._id">
                                    {{ phone.name }} - {{ phone.phone }}
                                </option>
                            </select>
                        </template>

                        <div class="d-flex align-items-center">
                            <button
                                v-if="$role('customers.update')" 
                                class="btn btn-primary mr-2"
                                type="button"
                                @click.prevent="updateCustomer(`customerFieldsForm-${item.data._id}`, item.data)"
                            >
                                Atualizar
                            </button>

                            <button 
                                v-if="$role('customers.delete')" 
                                class="btn btn-danger"
                                type="button"
                                @click.prevent="removeCustomer(item.data._id)"
                            >
                                Remover
                            </button>

                            <div v-if="item.data.contact != null && $role('chats.update')">
                                <a
                                    v-b-toggle="`marksSidebarCustomer${item.data._id}`"
                                    class="text-primary mx-4"
                                    role="button"
                                >
                                    Marcadores
                                </a>
                                <marks-sidebar
                                    :id="`marksSidebarCustomer${item.data._id}`"
                                    :value="item.data.contact"
                                />
                            </div>
                        </div>
                    </RenderCustomerFields>

                </section>
            </template>
        </common-table-custom>
    </section>
</template>

<script>
import {
    BLink,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormRating,
    BFormDatepicker,
    BButton,
} from "bootstrap-vue";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import Loading from "@/views/components/Loading.vue";
import {customersTableConfig, customersFilters} from './constants';
import api from '@/api';
import MarksSidebar from "../messages/modals/MarksSidebar.vue";
import RenderCustomerFields from "@/views/components/RenderCustomerFields.vue";
import VSelect from "vue-select";
import _ from "lodash";

export default {
    components: {
    CommonTableCustom,
    Loading,
    BLink,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormRating,
    BFormDatepicker,
    BButton,
    MarksSidebar,
    VSelect,
    RenderCustomerFields
},
    data() {
        return {
            loading: false,
            customersTable: customersTableConfig,
            customersFiltersOptions: {
                phones: [],
                campaigns: [],
                forms: [],
            },
            customersFilters: Object.assign({}, customersFilters)
        }
    },
    created() {
        this.getCustomers();
        this.getCampaigns();
        this.getPhones();
        this.getForms(this);
    },
    methods: {
        toast(message, type = "danger") {
            this.$bvToast.toast(message, {
                title: 'Clientes',
                autoHideDelay: 2000,
                variant: type
            })
        },
        getCustomers(page = 1) {
            this.loading = true;

            var params = {
                ...this.customersFilters,
                page
            };

            api
                .get('/customers', {params})
                .then(response => {
                    const {type, body, misc} = response.data;
                    if (type == 'success') {
                        this.customersTable.items = page > 1
                            ? [...this.customersTable.items, ...body]
                            : this.customersTable.items = body;
                        this.customersTable.misc = misc;
                    }
                })
                .finally(() => this.loading = false)
        },
        updateCustomer(ref, customer) {
            this.$refs[ref].$refs.form.validate().then(success => {
                if(!success) return;
                
                const id = customer._id;
                const phone_id = customer.phone_id;
                const data = customer.fields;

                this.$swal({
                    title: 'Atualizar Cliente',
                    text: 'Deseja atualizar esse cliente com os dados inseridos?',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Atualizar',
                    confirmButtonColor: "#317f98",
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                        return await api.put(`/customers/${id}`, {
                            data: data,
                            phone_id
                        });
                    }
                })
                    .then(result => {
                        if (!result.isConfirmed) return;

                        const {type, message} = result.value.data;

                        if (type == 'success') 
                            this.getCustomers();

                        this.toast(message, type == 'success' ? 'success' : 'danger');
                    })
                    .catch(error => {
                        let message = "Houve um erro ao atualizar cliente";
                        if (error.response)
                            message = error.response.data.message;

                        this.toast(message);
                    })
            });
        },
        removeCustomer(id) {
            this.$swal({
                title: 'Remover Cliente',
                text: 'Tem certeza que deseja remover esse cliente?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    return await api.delete(`/customers/${id}`);
                }
            })
                .then(result => {
                    if (!result.isConfirmed) return;

                    const {type, message} = result.value.data;

                    if (type == 'success') this.getCustomers();

                    this.toast(message, type == 'success' ? 'success' : 'danger');
                })
                .catch(error => {
                    if (error.response) {
                        return this.toast(error.response.data.message, 'danger');
                    }
                    this.toast('Houve um erro ao remover cliente, tente novamente em alguns instantes.', 'danger');
                })
        },
        clearFilters() {
            this.customersFilters = {...customersFilters};
        },
        getResource(route, query = {}) {
            return api.get(route, {params: query})
                .then(res => {
                    const {type, body, message} = res.data;

                    if (type == 'success') {
                        return body;
                    }
                    throw message;
                })
                .catch(error => {
                    console.log(error);
                    return [];
                })
        },
        getCampaigns() {
            this.getResource('/campaigns', {all: 1, only: ['_id', 'name']})
                .then(campaigns => {
                    this.customersFiltersOptions.campaigns = campaigns.map(e => {
                        return {
                            name: e.name,
                            _id: e._id
                        }
                    });
                })
        },
        getPhones() {
            this.getResource('/phones')
                .then(phones => {
                    this.customersFiltersOptions.phones = phones.map(e => {
                        return {
                            name: e.name,
                            phone: e.phone.replace(/(\d{2})(\d{2})(\d{1,})(\d{4})/, "+$1 ($2) $3-$4"),
                            _id: e._id,
                        }
                    });
                })
        },
        getForms: _.debounce((vm, loading = false, name = "") => {
            vm.getResource('/forms', {all: 1, only: ['_id', 'name'], search: name, order_column: 'name', order: 'asc', with_customers: true})
                .then(forms => {
                    vm.customersFiltersOptions.forms = forms.map(e => {
                        return {
                            name: e.name,
                            _id: e._id
                        }
                    });
                    if (loading) {
                        loading(false);
                    }
                });
        }, 500),

        onSearch(search, loading) {
                loading(true);
                this.getForms(this, loading, search)
        },
        parseFormValues(fields, values) {
            const keys = Object.keys(values);

            return Array.from(fields).map(row => {
                if(Array.isArray(row)) {
                    return row.map(col => {
                        if(keys.includes(col.name))
                            col.value = values[col.name];
                        return col;
                    })
                }
                if(keys.includes(row.name))
                    row.value = values[row.name];
                return row;
            });
        }
    }
}
</script>
<style>
.custom {
    color: #555;
    margin-bottom: 0.5rem;
}

.custom ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #555;
    font-size: 14px;
}

.custom .vs__selected {
    position: absolute;
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
}

.custom .vs__clear,
.custom .vs__open-indicator {
    fill: #555;
}

.max-container {
    max-width: 1200px;
}
</style>
