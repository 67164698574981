<template>
    <validation-observer ref="form" v-bind="$attrs" v-if="fields.length > 0">
        <slot name="start"></slot>

        <section :class="formClass">
            <div v-for="(row, rowId) in fields" :key="`customer-form-row-${rowId}`">
                <div class="row mb-3" v-if="row.constructor == Array">
                    <validation-provider
                        #default="{ errors }"
                        :rules="{ required: col.required, email: row.type == 'email' }"
                        v-for="(col, colId) in row"
                        class="col d-flex flex-column align-items-start"
                        :key="`customer-form-row-${rowId}-col-${colId}`"
                        :name="col.name"
                    >
                        <label v-if="$slots.label" class="form-label">
                            {{ col.name }}
                        </label>
                        <input
                            type="text"
                            class="form-control m-0"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            :placeholder="col.name"
                            v-model="col.value"
                            v-mask="masker(col.type)"
                        >
                        <small v-if="errors.length > 0" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </div>

                <div v-else class="row mb-3">
                    <validation-provider
                        #default="{ errors }"
                        :rules="{ required: row.required, email: row.type == 'email' }"
                        class="col d-flex flex-column align-items-start"
                        :name="row.name"
                    >
                        <label v-if="$slots.label" class="form-label">
                            {{ row.name }}
                        </label>
                        <input
                            type="text"
                            class="form-control m-0"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            :placeholder="row.name"
                            v-model="row.value"
                            v-mask="masker(row.type)"
                        >
                        <small v-if="errors.length > 0" class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </div>
            </div>
            <div class="row mb-3">
                <div v-if="$slots.mark" class="col-4 d-flex flex-column align-items-start">
                    <slot name="mark"></slot>
                </div>
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="número"
                    class="col-4 d-flex flex-column align-items-start"
                >
                    <slot name="phone"></slot>
                    <small v-if="errors.length > 0" class="text-danger position-absolute mt-5 ml-4">{{ errors[0] }}</small>
                </validation-provider>
            </div>
        </section>

        <slot></slot>
    </validation-observer>
</template>


<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mask } from 'vue-the-mask';
import { required, email } from "@validations";


export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    props: {
        fields: {
            type: Array,
            required: true
        },
        formClass: {
            type: String
        }
    },
    data() {
        return {
            required,
            email
        }
    },
    directives: {
        mask: (el, binding) => {
            if (!binding.value) return;
            mask(el, binding);
        },
    },
    methods: {
        masker(type) {
            const types = {
                main_phone: ['+## (##) #####-####', '+## (##) ####-####'],
                phone: ['+## (##) #####-####', '+## (##) ####-####'],
                date: '##/##/####',
                money: '###.###.###.###.###.###.###.###.###,##',
                number: '#'.repeat(255),
            }

            return type in types ? types[type] : null;
        }
    }
}
</script>
