<template>
    <section class="position-relative">
        <loading v-if="loading" />

        <common-table-custom
            :show-details="false"
            title="Formulários Existentes"
            v-bind="formsTable"
        >
            <template v-slot:filters>
                <div class="d-flex align-items-center">
                    <div class="flex-shrink">
                        <select
                            v-model="formsTable.filters.order_column"
                            class="form-control like-dropdown my-1"
                        >
                            <option :value="null" hidden selected>
                                Filtrar por
                            </option>
                            <option value="name">Nome</option>
                            <option value="created_at">Data de Criação</option>
                        </select>
                    </div>
                    <div class="flex-shrink">
                        <select
                            v-model="formsTable.filters.order"
                            class="form-control like-dropdown mx-md-2 my-1"
                        >
                            <option :value="null" hidden selected>
                                Classificar por
                            </option>
                            <option value="asc">Ascendente</option>
                            <option value="desc">Descendente</option>
                        </select>
                    </div>
                    <div
                        class="input-group input-group-merge search my-1 w-100 flex-grow-1"
                        role="group"
                        style="width: 300px !important"
                    >
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <feather-icon icon="SearchIcon" size="25" />
                            </div>
                        </div>
                        <input
                            v-model="formsTable.filters.search"
                            class="form-control"
                            placeholder="Buscar Formulário"
                            type="text"
                        />
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <td class="text-left">Total</td>
                <td>{{ formsTable.misc ? formsTable.misc.total : 0 }}</td>
                <td :colspan="5" class="text-right">
                    <b-link
                        v-if="
                            formsTable.misc &&
                            formsTable.misc.current_page !=
                                formsTable.misc.last_page
                        "
                        @click="getForms(formsTable.misc.current_page + 1)"
                    >
                        + Mostrar mais
                    </b-link>
                </td>
            </template>
        </common-table-custom>
    </section>
</template>

<script>
import { BLink } from "bootstrap-vue";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import Loading from "@/views/components/Loading.vue";
import { formsTableConfig } from "./constants";
import api from "@/api";

export default {
    components: {
        CommonTableCustom,
        Loading,
        BLink,
    },
    data() {
        return {
            loading: false,
            formsTable: {
                ...formsTableConfig,
                actions: [
                    {
                        label: "Duplicar",
                        name: "duplicate",
                        onClick: this.duplicateForm,
                        role: "forms.create",
                    },
                    {
                        label: "Editar",
                        name: "edit",
                        onClick: (id) =>
                            this.$router.replace({
                                name: "forms/edit",
                                params: { id },
                            }),
                        role: "forms.update",
                    },
                    {
                        label: "Deletar",
                        name: "delete",
                        onClick: this.removeForm,
                        if: (item) => !item.integration_id,
                        role: "forms.delete",
                    },
                ].filter((e) => this.$role(e.role)),
            },
        };
    },
    created() {
        this.getForms();
    },
    watch: {
        "formsTable.filters": {
            handler() {
                clearTimeout(this.formsTable.timeout);
                this.formsTable.timeout = setTimeout(this.getForms, 500);
            },
            deep: true,
        },
    },
    methods: {
        toast(message, type = "danger") {
            if (type == "error") type = "danger";

            this.$bvToast.toast(message, {
                title: "Formulários",
                autoHideDelay: 2000,
                variant: type,
            });
        },
        getForms(page = 1) {
            this.loading = true;

            var params = { ...this.formsTable.filters, page };

            api.get("/forms", { params })
                .then((response) => {
                    const { type, body, misc } = response.data;
                    if (type == "success") {
                        this.formsTable.items =
                            page > 1
                                ? [...this.formsTable.items, ...body]
                                : (this.formsTable.items = body);
                        this.formsTable.misc = misc;
                    }
                })
                .finally(() => (this.loading = false));
        },
        duplicateForm(id) {
            this.$swal({
                title: "Tem certeza que deseja duplicar este formulário?",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "Duplicar",
                confirmButtonColor: "#317f98",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api
                        .post(`/forms/${id}`)
                        .then((res) => {
                            const { type, message, body } = res.data;
                            if (type != "success") {
                                throw new Error(message);
                            }
                            const i = this.formsTable.items.findIndex(
                                (e) => e._id == id
                            );
                            this.formsTable.items.splice(i + 1, 0, body);
                            this.formsTable.misc.total += 1;
                        })
                        .catch(() => {
                            this.$swal(
                                "Erro",
                                "Não foi possível duplicar este formulário",
                                "danger"
                            );
                        });
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            });
        },
        removeForm(id) {
            const form = this.formsTable.items.find((e) => e._id == id);

            if (form && form.customers_count > 0) {
                return this.toast(
                    "Você não pode remover um formulário com clientes atrelados.",
                    "danger"
                );
            }

            this.$swal({
                title: "Remover Formulário",
                text: "Tem certeza que deseja remover esse formulário?",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "Remover",
                confirmButtonColor: "#317f98",
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    return await api.delete(`/forms/${id}`);
                },
            })
                .then((result) => {
                    if (!result.isConfirmed) return;

                    const { type, message } = result.value.data;

                    if (type == "success") this.getForms();

                    this.toast(message, type);
                })
                .catch((error) => {
                    if (error.response) {
                        return this.toast(
                            error.response.data.message,
                            "danger"
                        );
                    }
                    this.toast(
                        "Houve um erro ao remover formulário, tente novamente em alguns instantes.",
                        "danger"
                    );
                });
        },
    },
};
</script>
