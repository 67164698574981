<template>
    <div v-bind="$attrs">
        <div class="mb-3">
            <h6 class="d-flex align-items-start">
                <div
                    @click="() => $emit('select')"
                    class="btn text-left font-weight-bold px-0 w-100"
                    :style="{ color: mark.color || '#000' }"
                >
                    <svg fill="none" height="20" style="transform: rotateY(180deg);" viewBox="0 0 20 24"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                            :fill="mark.color || '#000'"
                            d="M2.59 11.58L11.59 2.58C11.95 2.22 12.45 2 13 2H20C21.1 2 22 2.9 22 4V11C22 11.55 21.78 12.05 21.41 12.42L12.41 21.42C12.05 21.78 11.55 22 11 22C10.45 22 9.95 21.78 9.59 21.41L2.59 14.41C2.22 14.05 2 13.55 2 13C2 12.45 2.23 11.94 2.59 11.58ZM11 20.01L20 11V4H13V3.99L4 12.99L11 20.01V20.01ZM16 6.5C16 7.33 16.67 8 17.5 8C18.33 8 19 7.33 19 6.5C19 5.67 18.33 5 17.5 5C16.67 5 16 5.67 16 6.5Z"/>
                    </svg>
                    &nbsp; {{ mark.name || "(Sem nome)" }}
                </div>

                <div 
                    v-if="selected" 
                    class="btn px-2"
                    @click="() => $emit('remove')"
                    v-b-tooltip="'Desvincular'"
                >
                    <font-awesome-icon 
                        icon="fa-solid fa-close"
                    />
                </div>
                <b-dropdown
                    v-else
                    size="md" 
                    variant="link" 
                    toggle-class="text-decoration-none border-0" 
                    no-caret
                >
                    <template #button-content>
                        <font-awesome-icon icon="fa-solid fa-ellipsis-vertical"/>
                    </template>
                    <b-dropdown-item 
                        @click="() => $emit('edit')"
                    >
                        Editar
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="() => $emit('destroy')"
                    >
                        Apagar
                    </b-dropdown-item>
                </b-dropdown>
            </h6>
            <p v-if="mark.description">{{ mark.description }}</p>
        </div>
    </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';

export default {
    components: {
        BDropdown, 
        BDropdownItem
    },
    props: {
        mark: Object,
        selected: {
            type: Boolean,
            default: false
        }
    },
    emits: ['remove', 'select', 'edit', 'destroy'],
}
</script>

<style scoped>
h6 {
    text-align: left;
    margin-bottom: -2px;
}

p {
    text-align: left;
    margin-left: 36px;
}
</style>
