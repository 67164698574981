<template>
    <section class="position-relative">
        <loading v-if="loading"/>

        <common-table-custom
            :show-details="false"
            title="Respostas recebidas"
            v-bind="customersAnswersTable"
        >
            <template v-slot:filters>
                <div class="d-flex align-items-center">
                    <div class="flex-shrink">
                        <select v-model="customersAnswersTable.filters.order_column"
                                class="form-control like-dropdown my-1">
                            <option :value="null" hidden selected>Filtrar por</option>
                            <option value="answer">Resposta</option>
                            <option value="created_at">Data de Criação</option>
                        </select>
                    </div>
                    <div class="flex-shrink">
                        <select v-model="customersAnswersTable.filters.order"
                                class="form-control like-dropdown mx-md-2 my-1">
                            <option :value="null" hidden selected>Classificar por</option>
                            <option value="asc">Ascendente</option>
                            <option value="desc">Descendente</option>
                        </select>
                    </div>
                    <div class="input-group input-group-merge search my-1 w-100 flex-grow-1" role="group"
                         style="width: 300px !important;">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <feather-icon icon="SearchIcon" size="25"/>
                            </div>
                        </div>
                        <input
                            v-model="customersAnswersTable.filters.search"
                            class="form-control"
                            placeholder="Buscar resposta"
                            type="text"
                        >
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <td class="text-left">Total</td>
                <td>{{ customersAnswersTable.misc ? customersAnswersTable.misc.total : 0 }}</td>
                <td
                    :colspan="5"
                    class="text-right"
                >
                    <b-link
                        v-if="customersAnswersTable.misc && customersAnswersTable.misc.current_page != customersAnswersTable.misc.last_page"
                        @click="getCustomersAnswers(customersAnswersTable.misc.current_page + 1)"
                    >
                        + Mostrar mais
                    </b-link>
                </td>
            </template>
        </common-table-custom>
    </section>
</template>

<script>
import {
    BLink
} from "bootstrap-vue";
import CommonTableCustom from "@/views/components/CommonTableCustom.vue";
import Loading from "@/views/components/Loading.vue";
import {customersAnswersTableConfig} from './constants';
import api from '@/api';

export default {
    components: {
        CommonTableCustom,
        Loading,
        BLink,
    },
    data() {
        return {
            loading: false,
            customersAnswersTable: {
                ...customersAnswersTableConfig,
                actions: [
                    {
                        label: "Deletar",
                        name: "delete",
                        onClick: this.removeCustomersAnswers,
                        role: 'answers.delete'
                    },
                ].filter(e => this.$role(e.role))
            },
        }
    },
    created() {
        this.getCustomersAnswers();
    },
    watch: {
        "customersAnswersTable.filters": {
            handler() {
                clearTimeout(this.customersAnswersTable.timeout);
                this.customersAnswersTable.timeout = setTimeout(this.getCustomersAnswers, 500);
            },
            deep: true
        }
    },
    methods: {
        toast(message, type = "danger") {
            if (type == 'error') type = 'danger';

            this.$bvToast.toast(message, {
                title: 'Respostas',
                autoHideDelay: 2000,
                variant: type
            })
        },
        getCustomersAnswers(page = 1) {
            this.loading = true;

            var params = {...this.customersAnswersTable.filters, page};

            api
                .get('/campaigns/answers', {params})
                .then(response => {
                    const {type, body, misc} = response.data;
                    if (type == 'success') {
                        this.customersAnswersTable.items = page > 1
                            ? [...this.customersAnswersTable.items, ...body]
                            : this.customersAnswersTable.items = body;
                        this.customersAnswersTable.misc = misc;
                    }
                })
                .finally(() => this.loading = false)
        },
        removeCustomersAnswers(id) {
            this.$swal({
                title: 'Remover Resposta',
                text: 'Tem certeza que deseja remover essa resposta?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    return await api.delete(`/campaigns/answers/${id}`);
                }
            })
                .then(result => {
                    if (!result.isConfirmed) return;

                    const {type, message} = result.value.data;

                    if (type == 'success') this.getCustomersAnswers();

                    this.toast(message, type);
                })
                .catch(error => {
                    if (error.response) {
                        return this.toast(error.response.data.message, 'danger');
                    }
                    this.toast('Houve um erro ao remover resposta, tente novamente em alguns instantes.', 'danger');
                })
        },
    }
}
</script>
