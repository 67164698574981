<template>
    <section id="dashboard-register">
        <b-row class="title mb-2">
            <b-col class="text-start">
                <h2 class="text-left">Cadastros</h2>
            </b-col>
        </b-row>
        <b-row class="mb-5">
            <b-col v-for="opt in options.filter(e => $role(e.roles, 'and'))" :key="opt.id">
                <custom-options
                    :opt="opt"
                    v-on:click.native="currentOpt = opt"
                    :class="[{ active: currentOpt.id == opt.id }, 'option']"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <register-tabs />
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import CustomOptions from "@/views/components/CustomOptions.vue";
import RegisterTabs from "./RegisterTabs.vue";
let options = [
    {
        id: 1,
        title: "Estrutura de cadastros",
        description:
            "Crie ou edite novos formulários para importação de dados.",
        icon: require("@/assets/images/icons/hierarchy.svg"),
        link: "forms/new",
        roles: 'forms.create'
    },
    {
        id: 2,
        title: "Importação Manual",
        description:
            "Realize o cadastro dos clientes digitando as informações individualmente.",
        icon: require("@/assets/images/icons/book-reading.svg"),
        link: "register/new",
        roles: ['forms.get', 'customers.create']
    },
    {
        id: 3,
        title: "Importação Automatizada",
        description:
            "Importe uma lista de contatos a partir de um documento.",
        icon: require("@/assets/images/icons/layout.svg"),
        link: "register/import",
        roles: ['forms.get', 'customers.import']
    },
];
export default {
    components: {
        BRow,
        BCol,
        CustomOptions,
        RegisterTabs,
    },
    data() {
        return {
            options: options,
            currentOpt: options[0],
        };
    },
    created() {},
    methods: {},
};
</script>
