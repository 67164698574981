<template>
    <b-sidebar
        @click.native.stop
        :id="id"
        lazy
        no-close-on-esc
        right
        shadow
        @shown="onShow"
    >
        <loading v-if="loading"/>

        <template v-slot:header="{ hide }">
            <div class="d-flex align-items-center py-4 px-3">
                <font-awesome-icon class="mr-5" icon="fa-solid fa-chevron-left" size="sm" @click.prevent.stop="hide"/>
                <h5 class="m-0 text-blue">Marcadores</h5>
            </div>
        </template>

        <div class="px-0 py-2 d-flex flex-column h-100 overflow-hidden">
            <div>
                <div class="input-group mb-3 mx-3 w-auto">
                    <span class="input-group-text">
                        <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                    </span>
                    <input
                        v-model="search"
                        :disabled="loading"
                        aria-label="Pesquisar"
                        class="form-control"
                        placeholder="Buscar um marcador"
                        type="text"
                    >
                </div>
            </div>

            <div class="d-flex flex-column flex-grow-1 overflow-auto px-3">
                <mark-item
                    v-if="contact.mark"
                    :mark="contact.mark"
                    @remove="() => removeMark()"
                    selected
                />

                <div 
                    v-if="contact.mark" 
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-2 mb-3 border-top"
                ></div>

                <mark-item
                    v-for="(mark, i) in marks.filter(e => contact.mark_id != e._id)" 
                    :key="`mark-card-${i}`"
                    :mark="mark"
                    @select="() => addMark(mark)"
                    @edit="() => editingMark = mark"
                    @destroy="() => destroyMark(mark._id)"
                />

                <p 
                    v-if="marks.filter(e => contact.mark_id != e._id).length == 0" 
                    class="m-0 text-center"
                >
                    Nenhum marcador encontrado
                </p>
            </div>
            <div v-if="$role(['marks.create'])" class="container p-3 d-flex justify-content-center">
                <span
                    v-b-modal.addMarkModal
                    class="font-weight-bold text-blue"
                    role="button"
                >
                    Criar um novo marcador
                </span>
            </div>
        </div>

        <add-mark-modal 
            @save="getMarks"
            v-model="editingMark"
        />
    </b-sidebar>
</template>

<script>
import {BSidebar} from "bootstrap-vue";
import AddMarkModal from "../modals/AddMarkModal.vue";
import MarkItem from "../components/MarkItem.vue";
import Loading from '@/views/components/Loading.vue';
import api from "@/api";

export default {
    components: {
        BSidebar,
        AddMarkModal,
        Loading,
        MarkItem
    },
    props: {
        value: {
            type: Object
        },
        id: {
            type: String,
            default: 'marksSidebar'
        }
    },
    emits: ['input'],
    data() {
        return {
            mainProps: {blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1'},
            marks: [],
            search: null,
            searchTimeout: null,
            contact: this.getValue(),
            loading: false,
            editingMark: null
        }
    },
    watch: {
        search() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => this.getMarks(false), 500);
        },
        editingMark(val) {
            if (val)
                this.$bvModal.show('addMarkModal')
            else
                this.$bvModal.hide('addMarkModal')
        }
    },
    methods: {
        getValue() {
            return JSON.parse(JSON.stringify(this.$props.value || {}));
        },
        removeMark() {
            Object.assign(this.contact, {
                mark: null,
                mark_id: null
            });
            this.saveCustomerMarks();
        },
        addMark(mark) {
            Object.assign(this.contact, {
                mark: mark,
                mark_id: mark._id
            });
            this.saveCustomerMarks();
        },
        onShow() {
            if(this.marks.length > 0) return;

            this.getMarks();
        },
        getMarks(loading = true) {
            this.loading = loading;

            const search = this.search;

            api
                .get('/marks', {params: {search}})
                .then(response => {
                    const {type, body} = response.data;

                    if (type == 'success')
                        return this.marks = body;

                    this.marks = [];
                })
                .catch(error => {
                    console.log(`getMarks: ${error}`);
                    this.marks = [];
                })
                .finally(() => this.loading = false)
        },
        destroyMark(id) {
            this.loading = true;

            api
                .delete(`/marks/${id}`)
                .then(response => {
                    const { type } = response.data;

                    if (type === 'success')
                        this.getMarks();
                })
                .catch(() => {
                    this.$bvToast.toast('Não foi possível remover marcador.', {
                        title: 'Marcadores',
                        variant: 'danger'
                    });
                })
                .finally(() => this.loading = false)
        },
        saveCustomerMarks() {
            let contact = this.contact;
            api
                .put(`/chats/${contact._id}`, {mark_id: contact.mark_id})
                .then(response => {
                    const {type, body} = response.data;

                    if (type == 'success') {
                        Object.assign(contact, body);
                        this.$store.commit('chat/SET_CONTACT', contact)
                        return this.$emit('input', contact);
                    }
                    throw response;
                })
                .catch((e) => {
                    console.log(e)
                    this.$bvToast.toast('Não foi possível adicionar marcador.', {
                        title: 'Marcadores',
                        variant: 'danger'
                    });
                    this.contact = this.getValue();
                })
        }
    },
}
</script>

<style scoped>
h6 {
    text-align: left;
    margin-bottom: -2px;
}

p {
    text-align: left;
    margin-left: 36px;
}
</style>
