<template>
    <b-tabs align="left" nav-class="font-weight-bold">
        <b-tab v-if="$role('forms.get')" lazy title="Meus dados">
            <b-card no-body>
                <b-card-body class="text-center">
                    <forms-table />
                </b-card-body>
            </b-card>
        </b-tab>
        <b-tab v-if="$role('answers.get')" lazy title="Respostas dos clientes">
            <b-card no-body>
                <b-card-body class="text-center">
                    <customers-answers-table />
                </b-card-body>
            </b-card
            >
        </b-tab>
        <b-tab v-if="$role('customers.get')" lazy title="Comportamento dos clientes">
            <b-card no-body>
                <b-card-body class="text-center position-relative">
                    <customers-table />
                </b-card-body>
            </b-card>
        </b-tab>
    </b-tabs>
</template>

<script>
import {
    BCard,
    BCardBody,
    BTab,
    BTabs,
} from "bootstrap-vue";
import CustomersTable from "./CustomersTable.vue";
import CustomersAnswersTable from "./CustomersAnswersTable.vue";
import FormsTable from "./FormsTable.vue";

export default {
    components: {
        BCard,
        BCardBody,
        BTab,
        BTabs,
        CustomersTable,
        CustomersAnswersTable,
        FormsTable
    },
};
</script>
