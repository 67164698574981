<template>
    <div class="custom-options">
        <b-link :disabled="opt.isButton" :to="{ name: opt.link }">
            <b-card body-class="my-4" style="height: 24rem;">
                <b-avatar class="mb-1" size="6rem" variant="light">
                    <img :src="opt.icon" class="d-block img-fluid"/>
                </b-avatar>
                <b-card-body>
                    <b-card-title
                    ><span id="title" class="text-blue font-weight-bold">{{
                            opt.title
                        }}</span></b-card-title
                    >
                    <b-card-text class="mx-3">
                        <span id="font" class="text-black font-weight-normal">{{
                                opt.description
                            }}</span>
                    </b-card-text>
                    <b-button
                        v-if="opt.isButton"
                        :to="{ name: opt.link }"
                        class="w-75 font-weight-bold"
                        variant="primary"
                    >{{ opt.textButton }}
                    </b-button
                    >
                </b-card-body>
            </b-card>
        </b-link>
    </div>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardText,
    BCardBody,
    BAvatar,
    BLink,
    BButton,
} from "bootstrap-vue";

export default {
    props: ["opt"],
    components: {
        BCard,
        BCardTitle,
        BCardText,
        BCardBody,
        BAvatar,
        BLink,
        BButton,
    },
};
</script>

<style></style>
